var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[((!_vm.event || !_vm.event.id) || _vm.isLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"dashboard__container--body"},[_c('div',{staticClass:"whiteBack",staticStyle:{"width":"100%","overflow":"auto"}},[_c('h4',[_vm._v("Manage Shifts")]),(!_vm.eventShifts || _vm.eventShifts.length == 0)?_c('Loader'):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.eventShifts,"styleClass":"vgt-table condensed","search-options":{
	              enabled: false,
	              placeholder: 'Search this table',
	            },"pagination-options":{
	              enabled: true,
	              mode: 'records',
	              perPage: 25,
	            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span'):(props.column.field == 'day')?_c('span',[(props.row.type == 'Permanent' || props.row.type == 'Job')?_c('span'):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.day,"MM/DD/YYYY"))+" ")])]):(props.column.field == 'type')?_c('span',[(props.row.eventInfo && props.row.eventInfo.shiftType)?_c('span',[_vm._v(_vm._s(props.row.eventInfo.shiftType))]):_vm._e()]):(props.column.field == 'visible')?_c('span',[(props.row.visible)?_c('i',{staticClass:"fa-solid fa-check",staticStyle:{"color":"green"}}):_vm._e()]):(props.column.field == 'staffingStatus')?_c('span',[_vm._v(" "+_vm._s(props.row.staffingStatus)+" ")]):(props.column.field == 'startTime')?_c('span',[_vm._v(" "+_vm._s(_vm.formatAMPM(props.row.startTime))+" ")]):(props.column.field == 'endTime')?_c('span',[_vm._v(" "+_vm._s(_vm.formatAMPM(props.row.endTime))+" ")]):(props.column.field == 'edit')?_c('span',[_c('router-link',{attrs:{"to":`/orders/` + _vm.eventInfo.id + `/setup/shifts/` + props.row.id}},[_c('button',{staticClass:"btn btn__small btn__outlined"},[_vm._v("Open")])])],1):(props.column.field == 'link')?_c('span',[_c('router-link',{attrs:{"to":`/orders/` + _vm.eventInfo.id + `/staffing/placements`}},[_c('button',{staticClass:"btn btn__small btn__primary"},[_vm._v("Placements")])])],1):(props.column.field == 'form')?_c('span',[_c('a',{attrs:{"href":`https://jumpstaffing.com/form/` + props.row.id,"target":"_blank"}},[_c('button',{staticClass:"btn btn__small btn__outlined mr-3 mb-2"},[_vm._v("Web Form")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }